.it-cta-form {
    & .input-item {
      position: relative;
      font-size: 16px;
      margin-bottom: 20px;
  
      & input,
      & select,
      & .nice-select {
        width: 100%;
        height: 60px;
        padding-left: 45px;
        padding-right: 10px;
        border-radius: 8px;
        border: 2px solid #ededed;
        background-color: transparent;
  
        @include tp-placeholder {
          color: #032b5f;
        }
        &:focus {
          border-color: #bbbbbb;
        }
      }
  
      & span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
        color: #bbbbbb;
        &.current {
          padding-left: 17px;
        }
      }
    }
  }

  .input-item-textarea {
    position: relative;
    font-size: 16px;
    margin-bottom: 20px;
  
    & textarea {
      padding: 20px 45px;
      width: 100%;
      height: 200px;
      resize: none;
      border: 2px solid #ededed;
      border-radius: 8px;
      background-color: transparent;
  
      @include tp-placeholder {
        color: #032b5f;
      }
  
      &:focus {
        outline: 0;
        border-color: #bbbbbb;
      }
    }
  
    & span {
      position: absolute;
      top: 34px;
      transform: translateY(-50%);
      left: 25px;
      color: #bbbbbb;
    }
  }

  .it-cta-form-submit {
    width: 100%;
    height: 60px;
    text-align: center;
    background-color: var(--tp-theme-orange);
    border: 2px solid #ededed;
    border-radius: 8px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    text-transform: uppercase;
  
    &:hover {
      background-color: #bbbbbb;
    }
  }