.selectpicker {
  border: 2px solid #c2e7ff;
  outline: none;
  border-radius: 50px;
  padding: 5px 10px;
  width: 100px;

  font-size: 16px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  background-image: url("./assets/new/down-arrow.png");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  margin-bottom: 10px;
}

.dropdownCustom .dropdown-toggle {

  box-sizing: border-box !important;
  display: inline-block;
  border: 2px solid #c2e7ff;
  border-radius: 50px;
  padding: 5px 10px;
  width: 100px !important;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.dropdownCustom .dropdown-toggle::after {
  font-size: 25px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.dropdownCustom .dropdown-menu-custom {
  color:#000 !important;
  position: absolute;
  bottom: -2px;
  left: 1px;
  transform: translateY(100%);
  width: 95px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  z-index: 100;
}

.dropdownCustom .dropdown-menu-custom li {
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 0px !important;
  background-color: #fff !important;
 
}
.dropdownCustom .dropdown-menu-custom li:not(:last-child) {
  border-bottom: 1px solid rgb(224, 224, 224);
}
.dropdownCustom .dropdown-menu-custom li:hover {
  background-color: #c2e7ff;
}
.dropdownCustom .dropdown-toggle img{
  vertical-align: middle !important;
}