@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url("./form.css");
@import url(./google-picker.css);
@import url(./google-translate.css);

/* ===================================================================== Color variables  */
:root {
  --text-primary: #17233d;
  --white: #fff;
  --black: #000;
  --white2: #ffffffbe;
  --button: #0f3460;
  --button-hover: #0f3460cc;
  --text-hover: #71a5cb;
  --text2: #1f7bbd;
  --text-gray: rgba(81, 90, 110, 1);
  --card-bg: #0f34604d;
  --card-bg-secondary: #3e3e3e;
  --secondary: #fe5e45;
  --secondary-hover: #fe5e45cc;
  --border: rgba(81, 90, 110, 0.342);
  --list-color: #515a6e;
  --recent-post: #71a5cb33;
  --ourSol-card: #e9e9e9;
  --sliderBtn: #fe5e455f;

  --gradient-color: linear-gradient(
    180deg,
    rgba(113, 165, 203, 0.26) -14.95%,
    rgba(113, 165, 203, 0) 118.32%
  );

  --gradient-color2: linear-gradient(
    180deg,
    #fe5e45a6,
    rgba(203, 142, 113, 0) 118.32%
  );

  --gradient-color3: linear-gradient(to right, #0F3460, #71A5CB);


  --gradient-bg: linear-gradient(#110f24 0%, #05050b 100%);


  --contact-gradient: linear-gradient(
    180deg,
    rgba(113, 165, 203, 0.5) -92.49%,
    rgba(255, 255, 255, 0) 120.14%
  );

  --font-18: 18px;

  --text-new: #606060;
  --text-black: #161616;
  --text-light: #F9F9F9;
  --gradient-new: linear-gradient(282.32deg, #E3D1FF -4.09%, #DDE0FD 50.16%, #C2F0FF 104.4%);
  --gradient-new2: linear-gradient(278.67deg, #9454FE -11.63%, #4D5EF6 54.62%, #2BCCFF 120.87%);
  --gradient-new3: linear-gradient(101.15deg, #F3EBFF 0%, #FAFBFF 50%, #E0F7FF 100%);

}


body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  line-height: 21.09px;
}

a {
  text-decoration: none;
  color: inherit;
}
img {
  width: 100%;
}

/* =================================================================== Container styles */
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* =================================================================== Media queries for container responsiveness */
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.logoImg {
  display: inline-block;
}

.bannerHighlight {
  color: var(--secondary);
  font-size: 72px;
}

.buttonHighlight {
  background: var(--secondary);
  color: var(--white) !important;
}
.footerLinks {
  color: var(--text-primary);
  text-decoration: none;

}
.footerLinks:hover {
  color: var(--text-hover) !important;
}

.submenu {
  position: absolute;
  top: 2.5rem;
  background: #fff;
  min-width: 100px;
  padding: 1rem 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 999;
}

.submenu img {
  width: 20px;
}
.active > button {
  font-weight: 500 !important;
  color: var(--secondary);
}

/* ======================================================= banner transition */

.parentDiv {
  mask: linear-gradient(180deg, transparent, white 20%, white 80%, transparent);
}

.scroller1,
.scroller2 {
  max-height: 600px;
}

.scroller__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

[data-animated="true"] {
  overflow: hidden;
}

[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll 40s var(--_animation-direction, forwards) linear infinite;
  -webkit-animation: scroll 40s var(--_animation-direction, forwards) linear
    infinite;
}

[data-direction="right"] {
  --_animation-direction: reverse;
}

[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translateY(calc(-50% - 0.5rem));
    -webkit-transform: translateY(calc(-50% - 0.5rem));
    -moz-transform: translateY(calc(-50% - 0.5rem));
    -ms-transform: translateY(calc(-50% - 0.5rem));
    -o-transform: translateY(calc(-50% - 0.5rem));
  }
}

.tag-list {
  display: flex;
  flex-direction: column;
}

/* .parentDiv::before,
.parentDiv::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  background: var(--card-bg);
  z-index: -1;
  mask: linear-gradient(180deg, transparent, white 20%, white 80%, transparent);
}

.parentDiv::before {
  left: 4rem;
}

.parentDiv::after {
  right: 4rem;
} */

/* Media query for smaller screens */
@media screen and (max-width: 767px) {
  .parentDiv::before {
    left: 1rem;
  }

  .parentDiv::after {
    right: 1rem;
  }
}

.ourSol__image1 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.ourSol__image2 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
}

@media (min-width: 992px) {
  .ourSol__image1 {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 0;
  }

  .ourSol__image2 {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 0;
  }
}

.blogPage__card + .blogPage__card {
  margin-top: 2.5rem;
}

.postDesc p {
  color: var(--list-color);
}
.benefits__para > p {
  margin: 0;
}


.reload-btn {
  cursor: pointer;
}


.requestButton {
  display: flex;
  justify-content: flex-start;
}



/* @media (min-width: 992px) {
  .card__description > div p {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }
  .card__description > div ul {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }
} */


.card__description > div p {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.card__description > div ul {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}


.underlined__atag:hover {
  text-decoration: underline;
  
}


#First_Name, 
#Last_Name,
#Title,
#Email ,
#Phone,
#Description,
#CONTACTCF2{
  width: 100%;
}


#imgid1832254000001456002 {
  width: 200px;
 max-height: 62px;
}

#First_Name, 
#Last_Name,
#Title,
#Email ,
#Phone,
#CONTACTCF2 ,
#captch_input,
#Description{
  width: 100%;
  padding: 10px 0;
  padding-left: 5px;
  font-size: 16px;
  background-color: transparent;
  outline: none;
  border: 1px solid var(--border);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  
}


.zcwf_privacy {
  display: flex;
  align-items: center; 
}

.zcwf_col_lab {
  margin-bottom: 5px;
}


.zcwf_button {
  background-color: var(--button);
  color: var(--white);
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
}

.zcwf_button + .zcwf_button {
  margin-left: 10px;
}

.errorMessage {
  margin-top: 5px;
}


.zcwf_privacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#privacyErr1832254000001456002 {
  margin-left: 20px;
}

.reloadBtn {
  cursor: pointer;
  margin-left: 10px;
}

.errorMessage {
  font-size: 14px;

}

.zcwf_col_fld {
  display: flex;
  align-items: center;
}


.zcwf_privacy_txt a{
  text-decoration: underline;
}

.banner__main {
  
  overflow: hidden;
  position: relative;
  z-index: 0;

}



.card__desc {
  line-height: 25px;
}
.card__desc ul{
  text-align: left;
  margin-top: 10px;
}

.card__desc p {
  margin: 10px 0;
}



.accordion_desc {
  padding-top: 0;
  padding-bottom: 0;
}

.accordion_desc > p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.accordion_desc h3 {
  font-size: 16px !important;
  margin-top: 0;
  margin-bottom: 10px ;
}

.faq_accordion::before {
  background-color: transparent !important
}
p {
  font-family: "Lato", sans-serif !important;
}

.hello {
  background-color: transparent !important;
}

.footer-link {
  transition: all 0.3s;
  font-size: 13px !important;
}
.footer-link:hover {
  color: var(--secondary) !important;
}